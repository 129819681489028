<template lang="pug">
  .pane
    sidebar-title
      span {{ $t('feedbackPane.editFeedback') }}
    .sidebar-inner-box.pt-0
      sidebar-tab-wrapper(defaultTab="general")
        template(#content)
          sidebar-tab-content(id="general")
            input-element-chooser
              template(slot="beforeInput")
                .user-info-message.alert.alert-danger(v-if="noFeedbackAction") {{ $t('noFeedbackAction') }}
              .d-flex.pt-2
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{$t('type')}}
              om-chooser(:options="displayTypes" property="data.displayType")
              om-dropdown-input(label="onClick" property="selectedElement.data.onClick" :items="onClickOptions" :i18n="true")
              template(v-if="onClick === 'jumpToPage'")
                om-dropdown-input(label="choosedPage" property="selectedElement.data.choosePageBasedOn" :items="chosenPageOptions" :i18n="true")
                om-dropdown-input(
                  v-if="choosePageBasedOn === 'specificPage'"
                  label="feedbackPane.jumpTo"
                  property="selectedElement.data.jumpTo"
                  :items="choosablePages"
                  :i18n="false"
                )
                om-dropdown-input(
                  v-else-if="choosePageBasedOn === 'byRating'"
                  v-for="(option, index) in options"
                  :key="option.value"
                  :label="`feedbackOptions.${option.value}`"
                  :property="optionProperty(index, 'jumpTo')"
                  :items="choosablePages"
                  :i18n="false"
                )
              template(v-if="selectedElement.data.required")
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{$t('errorMessage')}}
                om-simple-input.mb-2.pb-6.bb-1(label="" property="selectedElement.data.errorMessage")
          sidebar-tab-predefined-style(property="selectedElement" component="feedback" editMobile)
            template(#before)
              custom-theme-style
              sidebar-accordion(:title="$t('display')")
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0(v-if="hasFeedbackQuestion")
                  | {{$t('question')}}
                  div.sidebar-title-tooltip-container.pdl-0(style="display: inline-block; vertical-align: middle;")
                    i.fa.fa-info.mx-2.basic-tooltip-color.tooltip-mark(v-tooltip="{content: $t('questionTooltip')}")
                om-simple-input.mb-2(v-if="hasFeedbackQuestion" label="" property="selectedElement.data.feedback.question")
                .mb-4.bb-1(v-if="displayType === 'yesno' || displayType === 'smiley'")
                  om-chooser(label="feedbackPane.iconType" :options="iconTypes[displayType]" property="data.iconType")
                  template(v-if="iconType === 'image'" v-for="(option, optionIndex) in options")
                    .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{ $t(`feedbackOptions.${option.value}`) }}
                    .sidebar-input-wrapper
                      .sidebar-feedback-image.sidebar-upload-area.mb-2(@click="imageManager(optionIndex)")
                        template(v-if="imageUrl(optionIndex)")
                          img(:src="imageUrl(optionIndex)")
                        i.fa(v-else :class="iconClass(option)")
                om-color-input(v-if="iconType === 'vector'" label="color" property="selectedElement.subElements.icon.desktop.color" typeOfComponent="feedback")
                om-range-input(label="iconSize" property="selectedElement.subElements.icon.$device.fontSize" :editMobile="true" typeOfComponent="feedback" :step="1")
                om-range-input(label="spacing" property="selectedElement.desktop.horizontalSpacing" typeOfComponent="feedback" :step="1")
              sidebar-accordion(v-if="hasFeedbackQuestion" :title="$t('questionFontEdit')")
                om-font(propsProperty="selectedElement" type="feedback" subPath="selectedElement.subElements.question.desktop" typeOfComponent="feedback")
                  template(#fontSize)
                    om-range-input(property="selectedElement.subElements.question.$device.fontSize" :editMobile="true" label="fontSize" typeOfComponent="feedback" :min="8" :max="90" :step="1")
          advanced-tab(
            slot="advancedTabContent"
            :spacingProps="spacingProps"
            :elementDefaultsExtras="elementDefaultsExtras"
          )
</template>
<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import { initOptions } from '@om/editor-ssr-shared/src/core/initializers/elements/feedback';
  import { get as _get } from 'lodash-es';
  import { OmFeedback } from '@/editor/shared/components';

  const { elementDefaultsExtras } = OmFeedback;

  export default {
    components: {
      FeedbackStyleChooser: () => import('@/editor/components/FeedbackStyleChooser.vue'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },

    data() {
      return {
        show: true,
        showOptions: false,
        showPlaceholder: false,
        showDisplay: false,
        chosenPageOptions: [
          { key: 'feedbackPane.specificPage', value: 'specificPage' },
          { key: 'feedbackPane.byRating', value: 'byRating' },
        ],
        displayTypes: [
          {
            image: require('@/assets/editor/svg/feedback-styles/stars.svg'),
            name: 'feedbackPane.displayTypes.stars',
            value: 'stars',
          },
          {
            image: require('@/assets/editor/svg/feedback-styles/smiley.svg'),
            name: 'feedbackPane.displayTypes.smiley',
            value: 'smiley',
          },
          {
            image: require('@/assets/editor/svg/feedback-styles/yesno.svg'),
            name: 'feedbackPane.displayTypes.yesno',
            value: 'yesno',
          },
        ],
        iconTypes: {
          smiley: [
            {
              image: require('@/assets/editor/svg/feedback-icons/default-smiley.svg'),
              name: 'feedbackPane.iconTypes.vector',
              value: 'vector',
            },
            {
              image: require('@/assets/editor/svg/feedback-icons/image-smiley.svg'),
              name: 'feedbackPane.iconTypes.image',
              value: 'image',
            },
          ],
          yesno: [
            {
              image: require('@/assets/editor/svg/feedback-icons/default-yesno.svg'),
              name: 'feedbackPane.iconTypes.vector',
              value: 'vector',
            },
            {
              image: require('@/assets/editor/svg/feedback-icons/image-yesno.svg'),
              name: 'feedbackPane.iconTypes.image',
              value: 'image',
            },
          ],
        },
        elementDefaultsExtras,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'selectedPage', 'pages', 'validationError']),
      ...mapGetters(['nestedAccess', 'pageCount', 'isEmbedded']),
      onClickOptions() {
        const options = [{ key: 'none', value: 'none' }];

        if (!this.isEmbedded) {
          options.push({ key: 'closePopup', value: 'closePopup' });
        }

        options.push(
          { key: 'nextPopup', value: 'nextPopup' },
          { key: 'jumpToPage', value: 'jumpToPage' },
        );

        return options;
      },
      onClick() {
        return this.selectedElement.data.onClick;
      },
      displayType() {
        return this.selectedElement.data.displayType;
      },
      iconType() {
        return this.selectedElement.data.iconType;
      },
      choosePageBasedOn() {
        return this.selectedElement.data.choosePageBasedOn;
      },
      choosablePages() {
        const pages = [
          ...this.pages.map((page, pageIndex) => ({ key: page.data.title, value: pageIndex + 1 })),
        ];
        pages.unshift({ key: 'None', value: 'none' });
        pages.splice(this.selectedPage + 1, 1);
        return pages;
      },
      options() {
        return this.selectedElement.data.form.customSettings.options;
      },
      jumpTo() {
        return this.selectedElement.data.jumpTo;
      },
      noFeedbackAction() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('noFeedbackAction')
        );
      },
      hasFeedbackQuestion() {
        const question = _get(this.selectedElement, 'data.feedback.question');
        return question && question.length;
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'feedback',
        };
      },
    },
    watch: {
      isRequired() {
        const errorValue = this.nestedAccess('selectedElement.data.errorMessage');
        if (errorValue === null || errorValue === undefined) {
          this.$store.commit('updateData', {
            property: 'selectedElement.data.errorMessage',
            value: this.$t('fieldRequired'),
          });
        }
      },
      displayType(displayType) {
        const triggeredByCustomTheme =
          this.selectedElement.data?.form?.customSettings?.triggeredByCustomTheme === true;

        if (triggeredByCustomTheme) {
          delete this.selectedElement.data.form.customSettings.triggeredByCustomTheme;
          return;
        }

        this.fixOptions(initOptions(displayType));

        if (displayType === 'stars') {
          this.selectedElement.data.iconType = 'vector';
        }
      },
      onClick(n) {
        if (n === 'jumpToPage' && this.jumpTo === 'none') {
          const firstPage = this.choosablePages[0];

          if (firstPage) {
            this.$store.commit('updateData', {
              property: 'selectedElement.data.jumpTo',
              value: firstPage.value,
            });
          }
        }
        this.fixOptions();
      },
      choosePageBasedOn() {
        this.fixOptions();
      },
      jumpTo() {
        this.fixOptions();
      },
      choosablePages() {
        this.fixOptions();
      },
      'selectedElement.data.feedback.question': function (v, o) {
        if (v.length && !o.length) {
          this.updateStyle({
            property: 'selectedElement.subElements.question.desktop.verticalSpacing',
            value: 15,
          });
        } else if (!v.length && o.length) {
          this.updateStyle({
            property: 'selectedElement.subElements.question.desktop.verticalSpacing',
            value: 0,
          });
        }
      },
    },
    methods: {
      ...mapMutations([
        'showImageManager',
        'setImageManagerDestination',
        'setSelectedPath',
        'updateStyle',
      ]),
      imageManager(index) {
        this.setImageManagerDestination({
          targetAttr: this.optionProperty(index, 'image'),
          simpleImage: true,
        });
        this.showImageManager();
      },
      updateOptions(options) {
        this.selectedElement.data.form.customSettings.options = options;
        this.$store.commit('updateData', {
          property: 'selectedElement.data.form.customSettings.options',
          value: this.selectedElement.data.form.customSettings.options,
        });
      },
      optionProperty(index, key) {
        return `selectedElement.data.form.customSettings.options.${index}.${key}`;
      },
      iconClass(it) {
        return {
          'fa-star-o': this.displayType === 'stars',
          'fa-thumbs-o-up': this.displayType === 'yesno' && it.value === 'yes',
          'fa-thumbs-o-down': this.displayType === 'yesno' && it.value === 'no',
          'fa-smile-o': this.displayType === 'smiley' && it.value === 'good',
          'fa-meh-o': this.displayType === 'smiley' && it.value === 'neutral',
          'fa-frown-o': this.displayType === 'smiley' && it.value === 'bad',
        };
      },
      imageUrl(index) {
        return this.options[index].image && this.options[index].image.imageUrl;
      },
      fixOptions(options = this.options) {
        const firstPage = this.choosablePages.length > 0 ? this.choosablePages[0].value : null;

        this.updateOptions(
          options.map((option) => {
            let action = option.action || 'none';
            let jumpTo = option.jumpTo || null;

            if (this.onClick === 'jumpToPage') {
              action = 'jumpToPage';

              if (this.choosePageBasedOn === 'specificPage') {
                jumpTo = this.jumpTo;
              } else {
                jumpTo = option.jumpTo;
              }

              if (
                jumpTo === null ||
                jumpTo === 'none' ||
                !this.choosablePages.find((p) => p.value === jumpTo)
              ) {
                jumpTo = firstPage;
              }
            } else if (this.onClick === 'nextPopup') {
              action = 'nextPopup';
              jumpTo = null;
            } else if (this.onClick === 'closePopup') {
              action = 'closePopup';
              jumpTo = null;
            } else if (this.onClick === 'none') {
              action = 'none';
              jumpTo = null;
            }

            return { ...option, action, jumpTo };
          }),
        );
      },
    },
  };
</script>
